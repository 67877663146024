import * as React from 'react'
import { useRef, useEffect, useState } from "react";
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby";
import mapboxgl from "!mapbox-gl";
import axios from "axios";

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";

import {
  getCookieValue,
  handleOnSubmit,
  isEmail,
  getNewsletterFields,
  handleNewsletterOnSubmit,
  handleOnChange,
} from '../utils/mod.js';

import "../styles/css/contact.css"

const Contact = ({ doc, lang }) => {

  const getUrl = typeof window !== 'undefined' ? window.location.href : '';
  const newsletterFields = getNewsletterFields(doc);

  function cl(link) { return createLink(link, lang); }
  const mapContainerRef = useRef(null)

  let mount = true;

  function formHubspotB(lastname, firstname, email, phone, company, message) {
    // Create the new request 
    let xhr = new XMLHttpRequest();
    let url = 'https://api.hsforms.com/submissions/v3/integration/submit/4309630/ddca6e18-d052-4d37-9c59-38867fdabeed';

    // Example request JSON:
    let data = {
      "submittedAt": Date.now(),
      "fields": [
        {
          "objectTypeId": "0-1",
          "name": "source_du_lead",
          "value": "Site internet MataCapital"
        },
        {
          "objectTypeId": "0-1",
          "name": "lastname",
          "value": lastname
        },
        {
          "objectTypeId": "0-1",
          "name": "firstname",
          "value": firstname
        },
        {
          "objectTypeId": "0-1",
          "name": "email",
          "value": email
        },
        {
          "objectTypeId": "0-1",
          "name": "company",
          "value": company
        },
        {
          "objectTypeId": "0-1",
          "name": "phone",
          "value": phone
        },
        {
          "objectTypeId": "0-1",
          "name": "message",
          "value": message
        }
      ],
      "context": {
        "hutk": getCookieValue('hubspotutk'),
        "pageUri": getUrl,
        "pageName": "Home"
      }
    }

    var final_data = JSON.stringify(data);

    xhr.open('POST', url);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        //alert(xhr.responseText); // Returns a 200 response if the submission is successful.
        formHubspotBTrue();
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        //alert(xhr.responseText); // Returns a 400 error the submission is rejected.  
        formHubspotBFalse();
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        //alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
        formHubspotBFalse();
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        //alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
        formHubspotBFalse();
      }
    }

    xhr.send(final_data);
  }

  function formHubspotBTrue() {
    let form = document.querySelector('.section-contact form');
    let placeholder = form.querySelectorAll('.placeholder');
    let btn = document.querySelector('.section-contact form .container-button button');
    setTimeout(() => {
      btn.classList.add('style-load');
      setTimeout(() => {
        btn.classList.remove('style-load');
        btn.classList.add('style-check');
        form.reset();
        placeholder.forEach(function (el) {
          el.classList.remove('style-fill');
        });
      }, 1500);
    }, 300);
  }

  function formHubspotBFalse() {
    let form = document.querySelector('.section-contact form');
    let input = form.querySelectorAll('.input');
    let btn = document.querySelector('.section-contact form .container-button button');
    setTimeout(() => {
      btn.classList.add('style-load');
      setTimeout(() => {
        btn.classList.remove('style-load');
        input.forEach(function (el) {
          el.classList.add('style-error');
        })
      }, 1500);
    }, 300);
  }

  const handleOnSubmitB = e => {
    let formValid = true;
    e.preventDefault();
    [].forEach.call(document.querySelectorAll('.section-contact .container-form .input'), function (input) {

      if (!input.classList.contains('style-disable')) {
        if (input.firstChild.value != null) {
          if (input.firstChild.value.length === 0) {
            formValid = false;
            input.firstChild.parentElement.classList.add('style-error');
          }
          else if (input.firstChild.getAttribute('type') === 'email') {
            if (!isEmail(input.firstChild)) {
              formValid = false;
              input.firstChild.parentElement.classList.add('style-error');
            }
            else {
              input.firstChild.parentElement.classList.remove('style-error');
            }
          }
          else {
            input.firstChild.parentElement.classList.remove('style-error');
          }
        }
      }
    });
    if (formValid) {
      if (document.querySelector('#select_service').value === "Jobs") {

        async function callNetlify() {
          await axios.post('/.netlify/functions/form-contact', {
            data: {
              firstname: document.querySelector('#cLastName').value,
              lastname: document.querySelector('#cFirstName').value,
              email: document.querySelector('#cEmail').value,
              phone: document.querySelector('#cPhone').value,
              object: document.querySelector('#cObject').value,
              company: null,
              message: document.querySelector('#cMessage').value,
              sendto: document.querySelector('#cSendtoJ').innerText,
            }
          });
        }
        formHubspotBTrue();
        callNetlify();
      } else if (document.querySelector('#select_service').value === "Presse") {
        async function callNetlify() {
          await axios.post('/.netlify/functions/form-contact', {
            data: {
              firstname: document.querySelector('#cLastName').value,
              lastname: document.querySelector('#cFirstName').value,
              email: document.querySelector('#cEmail').value,
              phone: document.querySelector('#cPhone').value,
              object: document.querySelector('#cObject').value,
              company: document.querySelector('#cEntreprise').value,
              message: document.querySelector('#cMessage').value,
              sendto: document.querySelector('#cSendtoP').innerText,
            }
          });
          formHubspotBTrue();
        }
        callNetlify();

      } else {
        formHubspotB(document.querySelector('#cLastName').value,
          document.querySelector('#cFirstName').value,
          document.querySelector('#cEmail').value,
          document.querySelector('#cPhone').value,
          document.querySelector('#cEntreprise').value,
          document.querySelector('#cMessage').value);
      }
    }
  }

  useEffect(() => {

    /* SECTION CONTACT */

    function phoneRestriction() {
      let input = document.getElementById('cPhone')
      input.addEventListener('input', () => {
        input.value = input.value.replace(/[^0-9]/g, '');
      })
    }
    phoneRestriction();

    function formOpacity() {
      let form = document.querySelector('.container-form');
      let select = document.querySelector('.container-dropdown select');
      let input = document.querySelectorAll('.section-contact .wrapper .container .container-form form .container-input .input');
      select.addEventListener("change", () => {
        if (select.options[select.selectedIndex].index !== 0) {
          form.classList.add('style-active');
          if (select.options[select.selectedIndex].index === 2) {
            input[4].classList.add('style-disable');
            input[4].firstChild.value = null;
          } else {
            input[4].classList.remove('style-disable');
          }
        }
        else {
          form.classList.remove('style-active');
        }
      })
    }

    formOpacity();

    function formSubmit() {
      let placeholder = document.querySelectorAll('.section-contact .wrapper .container .container-form form .container-input .input .placeholder');
      let field = document.querySelectorAll('.section-contact .wrapper .container .container-form form .container-input .input');
      // let btn = document.querySelector('.section-contact .wrapper .container .container-form form .container-button button');

      for (let i = 0; i < field.length; i++) {
        field[i].firstChild.addEventListener('input', () => {
          if (field[i].firstChild.value !== '') {
            placeholder[i].classList.add('style-fill');
          }
          else if (field[i].firstChild.value === '') {
            placeholder[i].classList.remove('style-fill');
          }
        })

      }

    }

    formSubmit();
    
    const newsletterContainer = document.querySelector('.section-newsletter .wrapper form .container-form');
    const newsletterBtn = newsletterContainer.querySelector('.container button');
    handleNewsletterOnSubmit(newsletterContainer, newsletterBtn);

    function textareaCount() {
      const textarea = document.querySelector('.section-contact textarea');
      let maxCount = textarea.maxLength;
      const count = document.querySelector('.section-contact .container-form .count span');
      textarea.onkeyup = (e) => {
        count.innerHTML = (maxCount - e.target.value.length);
      }
    };
    
    textareaCount();

    function textareaResize() {
      const textarea = document.querySelector('.section-contact textarea');

      textarea.addEventListener("input", function (e) {
        this.style.height = 0;
        this.style.height = this.scrollHeight + "px";
      });
    }
    textareaResize();

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: "pk.eyJ1IjoiNHVhdHJlIiwiYSI6ImNsMjh0cWo0OTBiOWkza21leWZ4cXp5MGkifQ.07S0JVa1OQNoHlt09qDhBQ",
      style: "mapbox://styles/4uatre/cl28u3k6b007u15pf2qx7ootv",
      center: [(doc.map_place.longitude + doc.map_place1.longitude) / 2, (doc.map_place.latitude + doc.map_place1.latitude) / 2],
      zoom: doc.map_zoom,
    });

    map.on('load', function () {
      map.resize();
      map.loadImage(
        doc.map_icon.url,
        (error, image) => {
          if (error) throw error;

          map.addImage('icn', image);

          map.addSource('point-1', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [doc.map_place.longitude, doc.map_place.latitude]
                  }
                }
              ]
            }
          });

          map.addSource('point-2', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': [doc.map_place1.longitude, doc.map_place1.latitude]
                  }
                }
              ]
            }
          });

          map.addLayer({
            'id': 'point-1',
            'type': 'symbol',
            'source': 'point-1',
            'layout': {
              'icon-image': 'icn',
              'icon-size': 0.65,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-allow-overlap': true,
            },
            'paint': {
              'icon-translate': [3, -26],
            }
          });

          map.addLayer({
            'id': 'point-2',
            'type': 'symbol',
            'source': 'point-2',
            'layout': {
              'icon-image': 'icn',
              'icon-size': 0.65,
              'icon-allow-overlap': true,
              'icon-ignore-placement': true,
              'text-allow-overlap': true,
            },
            'paint': {
              'icon-translate': [0, -26],
            }
          });
        }
      );
    });
    map.scrollZoom.disable();
    /* END SECTION CONTACT */
  }, [mount, doc.map_icon.url, doc.map_place.latitude, doc.map_place.longitude]);

  return (
    <>

      <section className="section-hero" id="s1">
        <div className="wrapper">
          <div className="container">
            <div className="container-text">
              <div className="container-path">
                <div className="path">
                  <a href="/">{RichText.asText(doc.hero_pathtxt1)}</a>
                </div>
                <div className="sep">\</div>
                <div className="path">
                  <a href={getUrl}>{RichText.asText(doc.hero_pathtxt2)}</a>
                </div>
              </div>
              <div className="container-title">
                <h1>{RichText.asText(doc.hero_titlefirst)}</h1>
                <h1>{RichText.asText(doc.hero_titlelast)}</h1>
              </div>
            </div>
            <div className="container-img">
              <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                    <path transform="scale(0.0015625)" d="M640 -5.08635e-05C639.812 169.669 572.326 332.336 452.345 452.316C332.365 572.297 169.687 639.794 0 640L-1.37041e-05 326.486C86.5644 326.373 169.551 291.94 230.762 230.736C291.972 169.532 326.409 86.5548 326.522 -3.71609e-05L640 -5.08635e-05Z" />
                  </clipPath>
                </defs>
              </svg>
              <img src={doc.hero_img.url} alt={doc.hero_img.alt} />
            </div>

          </div>
          <div className="obj-1"></div>
        </div>

      </section>

      <section className='section-contact' id="s2">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.form_title)}</h2>
            <p>
              Notre équipe sera ravie de vous répondre.
            </p>
          </div>
          <div className="container">
            <div className="container-text">
              <div className="container-dropdown">
                <select name="" id="select_service">
                  {doc.form_grp.map((item, i) => (
                    <option key={i} value={RichText.asText(item.text)} >{RichText.asText(item.text)}</option>
                  ))}
                </select>
                <svg className="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 18">
                  <use xlinkHref="/img/common/icn-arrow-2.svg#content"></use>
                </svg>
              </div>
            </div>
            <div className="container-form">
              <div id="cSendtoJ">{RichText.asText(doc.form_mailtoj)}</div>
              <div id="cSendtoP">{RichText.asText(doc.form_mailtop)}</div>
              <form onSubmit={handleOnSubmitB}>
                <div className="container-input">
                  <div className="input">
                    <input type="text" id="cFirstName" name="firstname" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_firstname)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="text" id="cLastName" name="lastname" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_lastname)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="email" id="cEmail" name="email" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_email)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="tel" id="cPhone" pattern="[0]{1}[0-9]{9}" maxLength="10" name="phone" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_phone)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="text" id="cEntreprise" name="company" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_entreprise)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <input type="text" id="cObject" name="object" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_object)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                  </div>
                  <div className="input">
                    <textarea maxLength="600" id="cMessage" name="message" />
                    <span className="placeholder">
                      {RichText.asText(doc.form_message)} <i>*</i>
                    </span>
                    <span className="error">
                      Champ invalide
                    </span>
                    <div className="count">
                      {RichText.asText(doc.form_count1)}<span>0</span>{RichText.asText(doc.form_count2)}
                    </div>
                  </div>
                  <div className="input">
                    <input name="cc-num" className="hidden" required="" value="HubspotCollectedFormsWorkaround" id="cc-num" />
                    <input name="title" className="hidden" value="CONTACT" />
                    <input name="sendto" className="hidden" value="alexis@callbruno.com" />
                  </div>
                </div>

                <p className='cg'>
                  {RichText.asText(doc.form_data)}
                  <br /><a className='lgl' href={cl(doc.form_lnklnk)} target={doc.form_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.form_lnktxt)}</a>
                </p>
                <p className="rqd">
                  <i>*</i> {RichText.asText(doc.form_rqd)}
                </p>
                <div className="container-button">
                  <button className='btn-submit'>
                    <div className="btn-text">{RichText.asText(doc.form_btntxt)}</div>
                    <div className="loading">
                      <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <use xlinkHref="/img/common/icn-load.svg#content"></use>
                      </svg>
                      <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                        <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                      </svg>
                    </div>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className='section-iframe' id="s3" ref={mapContainerRef}>
        <div className="wrapper">
          <div className="card card-1">
            <div className="name">
              {RichText.asText(doc.map_name)}
            </div>
            <div className="adress">
              {RichText.asText(doc.map_adress)}
            </div>
            <div className="phone">
              {RichText.asText(doc.map_phone)}
            </div>
          </div>
          <div className="card card-2">
            <div className="name">
              {RichText.asText(doc.map_name1)}
            </div>
            <div className="adress">
              {RichText.asText(doc.map_adress1)}
            </div>
            <div className="phone">
              {RichText.asText(doc.map_phone1)}
            </div>
          </div>
        </div>
      </section>

      <section className="section-newsletter" id="s4">
        <div className="wrapper">
          <div className="container-title">
            <h2>{RichText.asText(doc.newsletter_title)}</h2>
            <p>{RichText.asText(doc.newsletter_text)}</p>
          </div>
          <form onSubmit={(e) => handleOnSubmit(e, getUrl)}>
            <div className="container-form">
              <div className="container">
                <div className="container-input">
                {newsletterFields.map((field) => (
                    <div className="input" key={field.id}>
                      <input
                        type={field.id === "nEmail" ? "email" : "text"}
                        id={field.id}
                        onChange={handleOnChange}
                      />
                      <span className="placeholder">
                        {RichText.asText(field.content)} <i>*</i>
                      </span>
                      <span className="error">
                        Champ invalide
                      </span>
                    </div>
                  ))
                  }
                  <div className="input">
                    <input
                    name="cc-num"
                    className="hidden"
                    required=""
                    value="HubspotCollectedFormsWorkaround"
                    id="cc-num"
                    onChange={handleOnChange}
                  />
                  </div>
                </div>
                <button>
                  <span className='btn-text'>{RichText.asText(doc.newsletter_btntxt)}</span>
                  <div className="loading">
                    <svg className="load" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-load.svg#content"></use>
                    </svg>
                    <svg className="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
                      <use xlinkHref="/img/common/icn-Check.svg#content"></use>
                    </svg>
                  </div>
                </button>
              </div>
              <p className="rqd">
                <i>*</i> {RichText.asText(doc.newsletter_rqd)}
              </p>
              <div className="container-checkbox">
                <div className="checkbox">
                  <input type="checkbox" id="check1" />
                  <div className="text">
                    <p id="check1Txt">{RichText.asText(doc.newsletter_info1)}</p>
                    <a href={cl(doc.newsletter_lnklnk)} target={doc.newsletter_lnknt ? "_blank" : "_self"} rel="noreferrer">{RichText.asText(doc.newsletter_lnktxt)}</a>
                  </div>
                </div>
                <div className="checkbox">
                  <input type="checkbox" id="check2" />
                  <div className="text">
                    <p>{RichText.asText(doc.newsletter_info2)}</p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

    </>
  );
};

const InitContact = ({ data }) => {
  const lang = data.prismicContact.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const categories = data.allPrismicCategories.edges;
  const articles = data.allPrismicArticle.edges;
  const footer = JSON.parse(data.prismicFooter.dataString);
  const doc = JSON.parse(data.prismicContact.dataString);

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head
        doc={doc}
        lang={lang}
      />
      <div className="contact">
        <Layout
          focus={focus}
          setFocus={setFocus}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"contact"}
        >
          <main>
            <Contact
              doc={doc}
              lang={lang}
            />
          </main>
        </Layout>
      </div>
    </>
  );
};
export default InitContact;

export const query = graphql`
query callContact($lang: String) {
  prismicHeader(lang: { eq : $lang }) {
    dataString
  }
  allPrismicCategories(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            slugs
            dataString
        }
    }
  }
  allPrismicArticle(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            dataString
            first_publication_date
        }
    }
  }
  prismicContact(lang: {eq: $lang}) {
      dataString
      lang
      uid
  }
  prismicFooter(lang: {eq: $lang}) {
    dataString
  }
  allPrismicFocus(filter: {lang: {eq: $lang}}) {
    edges {
        node {
            prismicId
            dataString
            first_publication_date
            uid
        }
    }
  }
}
`